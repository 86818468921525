<template>
  <div class="verification">
    <div class="verification-loading" v-if="loader">
      <Loading></Loading>
    </div>
    <div class="verification-container" v-if="!loader">
      <div class="verification-container-status">
        <img
          v-if="!verificationStatus"
          class="status-logo"
          src="@/assets/icons/error@2x.svg"
          alt=""
        />
        <img
          v-else
          class="status-logo"
          src="@/assets/icons/success@2x.svg"
          alt=""
        />
        <p v-if="!verificationStatus && alreadyVerified" class="font-medium">
          Email is already verified.
        </p>
        <p v-if="!verificationStatus && !alreadyVerified" class="font-large">
          Verification Unsuccessful
        </p>
        <p v-if="verificationStatus" class="font-large">
          Verification Successful!
        </p>
        <p v-if="!verificationStatus && !alreadyVerified" class="font-medium">
          Sorry! Your account has not verified.
        </p>
        <p v-if="verificationStatus" class="font-medium">
          <!-- Your account has been verified successfully. -->
          Now you can login to your mobile app and enjoy.
        </p>
        <p v-if="!verificationStatus && !alreadyVerified" class="font-medium">
          Please try later.
        </p>
        <!-- <p v-if="verificationStatus" class="font-medium">
          Now you can <router-link to="/">login</router-link> and explore.
        </p> -->
      </div>
      <!-- <div class="verification-container-appLogo">
        <img src="@/assets/icons/vlive_logo.png" alt="" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/Templates/Loading.vue";
import Utility from "@/mixins/Utility.js";

export default {
  data() {
    return {
      verificationStatus: false,
      alreadyVerified: false,
      loader: true,
      formError: null,
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  watch: {
    getToken(val) {
      if (val) {
        this.verifyEmail();
      }
    },
  },
  // mounted() {
  //   this.verifyEmail();
  // },
  methods: {
    // ...mapActions(["subscriberEmailVerify"]),
    ...mapActions(["subscriberEmailConfirm"]),
    encodeUri(data) {
      var obj = {
        "%20": "+",
        "%21": "!",
        "%2A": "*",
        "%27": "'",
        "%28": "(",
        "%29": ")",
        "%3B": ";",
        "%3A": ":",
        "%40": "@",
        "%26": "&",
        "%3D": "=",
        "%2B": "+",
        "%24": "$",
        "%2C": ",",
        "%2F": "/",
        "%3F": "?",
        "%25": "%",
        "%23": "#",
        "%5B": "[",
        "%5D": "]",
      };

      for (let i in obj) {
        data = data.replace(new RegExp(i, "g"), obj[i]);
      }

      return data;
    },
    verifyEmail() {
      let queryParams = window.location.href.split("?")[1];
      let arr = queryParams.split("&");
      let payload = {};
      arr.forEach((element, index) => {
        let value = element.split("=")[1];
        if (index === 0) {
          payload.email = this.encodeUri(value);
        } else if (index === 1) {
          payload.token = value;
        } else if (index === 2) {
          payload.providerid = value;
        }
      });
      this.loader = true;
      console.log("verification payload", payload);
      this.subscriberEmailConfirm(payload)
        .then((response) => {
          this.loader = false;
          console.log("email verify response", response);
          if (response.data.reason) {
            this.verificationStatus = false;
            if (response.data.errorcode === 6009) {
              this.alreadyVerified = true;
            }
          } else if (response.data.success) {
            this.verificationStatus = true;
          } else {
            this.verificationStatus = false;
          }
        })
        .catch((error) => {
          if (error) {
            this.verificationStatus = false;
          }
        });
    },
  },
  components: {
    Loading,
  },
  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
.verification {
  &-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  &-container {
    display: grid;
    min-height: 100vh;
    justify-content: center;
    &-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .status-logo {
        margin-bottom: 1.6rem;
        height: 55px;
        width: 55px;
      }
      .font-large {
        color: #efeff4;
        font-family: "Helvetica Neue Bold", sans-serif;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 39px;
        margin-bottom: 1.4rem;
      }
      .font-medium {
        color: #868688;
        font-family: "Helvetica Neue", sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 23px;
      }
    }
    &-appLogo {
      text-align: center;
      img {
        height: 40px;
        width: 117px;
      }
    }
  }
}
</style>
